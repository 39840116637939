import * as React from "react"
import Header from "../components/header"
import HeaderTop from "../components/headertop"
import FooterForm from "../components/form-footer"
import Footer from "../components/footer"
import Bgour from "../components/bgour"
import * as ourStyles from "../components/ourproduct.module.css"
import Containers from "../components/container"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import SEO from "../components/seo"

// styles

// markup
const IndexPage = () => {
  return (
    <main>
      <SEO title="สินค้าของเรา" />
      <HeaderTop />
        <Header />
          <Bgour  />
          <Container fluid="xl" className={ourStyles.ourpd}>
            <Row className={ourStyles.rowpd}>
              <Col>
                  <Containers>
                      <Row>
                          <Col md={12}>
                              <p className={ourStyles.headerproduct}>Auto Packing Roll</p>
                          </Col>
                          <Col md={6}>
                              <StaticImage src="../images/product-auto.png" alt="ProductAuto" />
                          </Col>
                          <Col md={6} className={ourStyles.colproduct}>
                              <h3>Auto Packing Roll</h3>
                          </Col>
                        </Row>
                  </Containers>
              </Col>
            </Row>
            <Row className={ourStyles.rowpd}>
              <Col>
                  <Containers>
                      <Row>
                        <Col md={12}>
                          <p className={ourStyles.headerproduct}>Flexible Bag</p>
                        </Col>
                          <Col md={4}>
                              <StaticImage src="../images/product-flexible-01.png" alt="ProductFlexible01" />
                              <p className={ourStyles.fontpp}>ซีล 3 ด้าน (Three side se)</p>
                          </Col>
                          <Col md={4}>
                              <StaticImage src="../images/product-flexible-02.png" alt="ProductFlexible02" />
                              <p className={ourStyles.fontpp}>ซีลกลาง (Center seal)</p>
                          </Col>
                          <Col md={4}>
                              <StaticImage src="../images/product-flexible-03.png" alt="ProductFlexible03" />
                              <p className={ourStyles.fontpp}>ซองตั้ง (Stand-up Pouch)</p>
                          </Col>
                      </Row>
                  </Containers>
              </Col>
            </Row>
        </Container>
        <FooterForm />
      <Footer />
    </main>
  )
}

export default IndexPage